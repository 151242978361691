import { writable } from "svelte/store"


export class CustomWritable {
  constructor({ ...props }) {
    this.default = props.default
    this.dataSet = props.dataSet
    const { subscribe, set, update } = writable(this.default)
    this.subscribe = subscribe, this.set = set, this.update = update
    Object.entries(props).forEach(prop => {
      const [key, value] = prop
      this[key] = value
    })
  }

  reset() {
    this.set(this.default)
  }

  getData() {
    let result
    this.subscribe(state => result = state)()
    return result
  }
}
