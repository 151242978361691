<script>
  import { fade } from 'svelte/transition'
  import { fadeEffect } from './constants'
  import { searchResults } from './store'
  import { debounce } from './utils'
// Constants
  const DELAY = 500
// Props
  let search='',
      showAutoComplete
// Handlers
  const hideAutoComplete = () => showAutoComplete = false
  const setSearch = debounce(DELAY, async e => {
    if (search.length > 1) {
      showAutoComplete = true
      await searchResults.loadData({ params: { search } })
    } else hideAutoComplete()
      
  })
  const submit = e =>
    e.keyCode === 13 && search.length > 3 &&
      (window.location = `/catalog/?search=${search}`)
</script>

<div class="search-line" style="position:relative">
  <input id="search-string" type="text" placeholder="Поиск..."
    on:input={setSearch}
    on:blur={hideAutoComplete}
    on:keyup={submit}
    bind:value={search}
    autofocus
  />
  <div class="svg">
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.41471 12.8287C7.83796 12.8284 9.22019 12.352 10.3413 11.4753L13.8662 15L15 13.8663L11.4751 10.3416C12.3523 9.22038 12.8291 7.83791 12.8294 6.41437C12.8294 2.87765 9.95162 0 6.41471 0C2.8778 0 0 2.87765 0 6.41437C0 9.95109 2.8778 12.8287 6.41471 12.8287ZM6.41471 1.60359C9.068 1.60359 11.2257 3.76123 11.2257 6.41437C11.2257 9.06751 9.068 11.2251 6.41471 11.2251C3.76143 11.2251 1.60368 9.06751 1.60368 6.41437C1.60368 3.76123 3.76143 1.60359 6.41471 1.60359Z"/>
    </svg>
  </div>
</div>

{#if showAutoComplete}
  <div class="autocomplete" transition:fade={fadeEffect}>
    <div class="container">
      <ul>
        {#if $searchResults.loading}
          <li class="searching">Выполняется поиск...</li>
        {:else if ($searchResults.data || []).length > 0}
          {#each $searchResults.data as item}
            <li class="search-results-item"><a href="{item.url}">{item.city_title} - {item.title}</a></li>
          {/each}
            <li class="all-results"><a href="/catalog?search={search}">--Все результаты--</a></li>
        {:else}
          <li class="nothing-found">Ничего не найдено</li>
        {/if}
      </ul>
    </div>
  </div>
{/if}
