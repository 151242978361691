// URL params parsing
export const paramsFromURL = () => {
  const queryString = window.location.search;
  let parsedParams = new URLSearchParams(queryString);
  parsedParams = Array.from(parsedParams.entries());
  return Object.fromEntries(parsedParams.map(param => {
    const [key, value] = param;
    const re = /(.+)::(.+)/;
    const parsedValue = 
      key === 'specs'
      ? Object.fromEntries(
          value.split('@@').map(param => {
            const [fullMatch, key='', values=''] = param.match(re) || [];
            return [key, values.split('||')]
          })
        )
      : value.split('||');
    return [key, parsedValue];
  }))
}

export const urlFromParams = params => {
  let res = new URLSearchParams();
  Object.entries(params || {}).forEach(entry => {
    let [key, value] = entry;
    value = Array.isArray(value) ? value : [value];
    res.append(key, key === 'specs'
    ? Object.entries(value[0]).map(entry => {
        const [key, values] = entry;
        return [key, values.join('||')].join('::');
    }).join('@@')
    : value.join('||')
  );
  })
  return res.toString();
}

export const redirectPost = (url, data) => {
  var form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'post';
  form.action = url;
  for (let name in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = data[name];
    form.appendChild(input);
  }
  form.submit();  
}