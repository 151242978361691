export const _app = components => components.map(item => {
  const [Component, targetNode, { hydrate = true, ...params } = {}] = item;
  return new Component({
    target: document.getElementById(targetNode),
    hydrate, ...params
  })
})

export const toggleItem = (arr=[], item) =>
  arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];
