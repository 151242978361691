import { CustomWritable } from './base'
import { paramsFromURL, urlFromParams, toggleItem } from '../utils'
import { catalog } from './dataSet'

class UrlParams extends CustomWritable {
  constructor() {
    super({ default: {} })
  }

  setParams() {
    this.set(paramsFromURL())
  }

  getParamString() {
    return urlFromParams(
      this.getData())
  }

  toggleParam(k, v, { nested = null } = {}) {
    if (nested) {
      this.update(state => {
        const { [nested]: nestedState, page/*reset*/, ...restParams } = (state || {})
        const { [k]: values, ...restKeys } = (nestedState || {})
        const newValues = toggleItem(values, v)
        const newNestedState = {
          ...(newValues.length > 0 && { [k]: newValues }),
          ...restKeys
        }
        return {
          ...(Object.keys(newNestedState).length > 0 ? { [nested]: newNestedState } : {}),
          ...restParams // without `page`
        }
      })
    } else {
      this.update(state => {
        const { [k]: values, page/*reset*/, ...restParams } = {} = state
        const newValues = toggleItem(values, v)
        return {
          ...(newValues.length > 0 && { [k]: newValues }),
          ...restParams // without `page`
        }
      })
    }
  }

  setParam(k, v) {
    this.update(state => {
      const { page/*reset*/, ...restParams } = state
      return { ...restParams, [k]: v } // without `page`
    })
  }

  removeParam(k) {
    this.update(state => {
      const { [k]: values, page/*reset*/, ...restParams } = state || {}
      return restParams // without `page`
    })
  }

  async applyFilters({ pushState=true, redirect=null } = {}) {
    const params = this.getData()
    const paramString = this.getParamString()
    let { origin, pathname } = window.location
    if (!(redirect === null)) pathname = `/catalog/${redirect}`  // "" !== null
    const newUrl = `${origin}${pathname}${paramString ? `?${paramString}` : ''}`
    const endPoint = pathname.replace('/catalog/', '')
    if (pushState) window.history.pushState({ path: newUrl }, '', newUrl)
    await catalog.loadData({ endPoint, params })
    // moreProducts.reset()
  }
}

export const urlParams = new UrlParams();

// export const inputRange = writable()
