import { urlFromParams } from './url'

// export const HOST = document.getElementById("static-host").innerText
export const CATALOG = "CATALOG",
  SEARCH_RESULTS = "SEARCH_RESULTS"

const dataSetMap = dataSet => ({
  CATALOG: {
    url: "/api/offers/"
  },
  SEARCH_RESULTS: {
    url: "/api/search-results/"
  }
})[dataSet]

const getRequestOptions = ({ form, data }) => {
  let body
  if (form) {
    body = new FormData(form)
    return { body }
  } else if (data) {
    body = JSON.stringify(data)
    return {
      body,
      headers: { 'Content-Type': 'application/json' }
    }
  } else { return {} }
}

export const fetchData = ({ dataSet, endPoint, form, data, params, method="GET" }) => update =>
  (async () => {
    let { url } = dataSetMap(dataSet)
    if (endPoint) { url += endPoint }
    if (params) { url += `?${urlFromParams(params)}` }
    update(state => ({ ...state, loading: true }))
    const options = getRequestOptions({ form, data })
    const response = await fetch(url, { method, ...options })
      .catch(err => new Error(err))

    if (response instanceof Error) {
      update(state => ({ ...state, loading: false, error: response }))
      return
    }
    const jsonData = await response.json()
      .catch(err => new Error(err))
    const { detail, message } = jsonData;
    
    update(state => ({
      ...state, loading: false,
      ...(jsonData instanceof Error
        ? { error: jsonData }
        : detail || message
          ? { error: detail || message }
          : { data: jsonData, error: false }
      )
    }))
  })()
