import { readable, writable } from 'svelte/store'

export const getJsonContent = (name, func=readable) => {
  const dataElement = document.getElementById(name)
  return func(dataElement ? JSON.parse(dataElement.textContent || '\[\]') : [])
}

// Catalog tree
export const tree = getJsonContent('tree-data', writable)
export const recentlyViewedIds = getJsonContent('recently-viewed-ids-data')

// Snippets map
export const snippetsMap = getJsonContent('snippets-map-data')
