// CURRENCY
export const CURRENCY_SUFFIX = '&nbsp;Р';

// FILTERS
export const BASIC_FILTERS = {
  category_1: "Категория",
  category_2: "Группа товара",
  category_3: "Вид товара",
  brand: "Производитель",
}

export const ADDITIONAL_FILTERS = {
  color: "Цвет",
}

export const NESTED_FILTERS = {
  'specs': '<Характеристики>'
}

// TRANSITION EFFECTS
export const flyEffect = { x: -500, duration: 500 };
export const flyEffectNarrow = { x: -500, duration: 500 };
export const flyFromTop = { y: -500, duration: 500 };
export const flyFromRight = { x: 500, duration: 500 };
export const fadeEffect = { duration: 500 };
