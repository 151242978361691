import { CustomWritable } from './base'
import { fetchData, CATALOG, SEARCH_RESULTS } from "../utils/api"


// GENERIC DATASET

class DataSet extends CustomWritable {
  constructor(dataSet) {
    super({
      default: { loading: false, data: null },
      dataSet
    })
  }

  async loadData({ ...options } = {}) {
    await fetchData({ dataSet: this.dataSet, ...options })(this.update)
  }
}

// CATALOG

class Catalog extends DataSet {
  constructor() {
    super(CATALOG)
  }
}

class SearchResults extends DataSet {
  constructor() {
    super(SEARCH_RESULTS)
  }
}

export const catalog = new Catalog()
export const searchResults = new SearchResults()
